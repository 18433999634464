import { Injectable } from '@angular/core';
import { ApiRestService, BasePage, SearchResult } from '@motivforce/mx-library-angular';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { PromotionType } from '../../enum/promotion-type';
import { Promotion } from '../../model/promotion/promotion';
import { PromotionResult } from '../../model/promotion/promotion-result';
import { PromotionResultSearch } from '../../model/promotion/promotion-result-search';

@Injectable({
  providedIn: 'root',
})
export class PromotionRestService {
  constructor(protected apiRestService: ApiRestService) {}

  getPromotions(): Observable<Promotion[]> {
    return this.apiRestService.get<Promotion[]>(`${environment.api.core.baseUrl}/members/promotions/all-current/`);
  }

  searchPromotions(promotionSearch: BasePage): Observable<SearchResult<Promotion>> {
    return this.apiRestService.post<SearchResult<Promotion>>(
      `${environment.api.core.baseUrl}/members/promotions/search`,
      promotionSearch
    );
  }

  getPromotion(promotionId: number): Observable<Promotion> {
    return this.apiRestService.get<Promotion>(`${environment.api.core.baseUrl}/members/promotions/${promotionId}`);
  }

  getAvailableUserPromotions(type: PromotionType): Observable<Promotion[]> {
    return this.apiRestService.post<Promotion[]>(
      `${environment.api.core.baseUrl}/members/promotions/available-for-user`,
      {
        types: [type],
      }
    );
  }

  getActivePromotions(type: PromotionType): Observable<Promotion[]> {
    return this.apiRestService.post<Promotion[]>(`${environment.api.core.baseUrl}/members/promotions/active`, {
      types: [type],
    });
  }

  finishPromotion(promotionId: number): Observable<void> {
    return this.apiRestService.post<void>(
      `${environment.api.core.baseUrl}/members/promotions/${promotionId}/finish`,
      {}
    );
  }

  searchResults(resultSearch: PromotionResultSearch): Observable<SearchResult<PromotionResult>> {
    return this.apiRestService.post<SearchResult<PromotionResult>>(
      `${environment.api.core.baseUrl}/members/promotions/results`,
      resultSearch
    );
  }

  getWEPLoungeProducts(): Observable<Promotion[]> {
    return this.apiRestService.get<Promotion[]>(`${environment.api.core.baseUrl}/members/promotions/company-current`);
  }
}
